import React, { useState } from "react";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	DocPageMeta,
	HeaderSection,
	ContentSection,
	Knobs,
	Example,
	InlineMessage,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ActionBar, DestructiveActionBar } from "./action-bar";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Action Bar",
	notepad: "https://hackmd.io/3YxsbuKlQOawxNLNxp32oA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Action Bar"
			subTitle="Action bar is a container, houses horizontally aligned buttons that enable users to act on single or multiple items."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={ActionBar} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					A gray background container bar featuring a single primary action button. Ideal for simple
					tasks requiring only one action (e.g., "Submit", "Save")
				</Paragraph>
				<Paragraph>
					The <Code>ActionBar</Code> consists of:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Container Bar</b>: Provides visual separation with a gray background. This can be
							removed for a more integrated look by adding the <Code>noBackground</Code> property.
						</>,
						<>
							<b>Buttons</b>: Right-aligned buttons are the only controls allowed in an Action Bar.
							They trigger actions and should be limited to a maximum of 3.{" "}
							<Ul
								items={[
									<>
										<b>Primary</b>: The main action (e.g., "Save", "Submit"). Use only once.
									</>,
									<>
										<b>Secondary</b>: Less prominent actions (e.g., "Edit", "Add another").
									</>,
									<>
										<b>Cancel (Default)</b>: For neutral actions (e.g., "Cancel", "Close", "Reset")
									</>,
									<>
										<b>Destructive (Red)</b>: For irreversible actions (e.g., "Delete").
									</>,
								]}
							/>
						</>,
					]}
				/>
			</TextContainer>
			<Example fn={basicUsage} />
			<TextContainer article>
				<Header.H3>Loading</Header.H3>
				<Paragraph>
					Provides visual feedback during action processing by using a spinner to indicate loading,
					preventing duplicate actions.
				</Paragraph>
			</TextContainer>
			<Example fn={LoadingExample} />
			<TextContainer article>
				<Header.H3>Links</Header.H3>
				<Paragraph>
					Navigating users to new tabs or internal pages. Uses primary and secondary buttons for
					visual distinction. You can use label to indicate if the link opens in a new tab.
				</Paragraph>
			</TextContainer>
			<Example fn={LinkExample} />
			<TextContainer article>
				<Header.H3>No padding</Header.H3>
				<Paragraph>
					Seamlessly integrating the Action Bar with adjacent elements. Useful when the Action Bar
					needs to blend visually with its surroundings.
				</Paragraph>
			</TextContainer>
			<Example fn={NoPaddingExample} />
			<TextContainer article>
				<Header.H3>No background</Header.H3>
				<Paragraph>
					Prioritizing content over the Action Bar background. Employ when the Action Bar needs to
					be minimally intrusive, allowing the content to take precedence.
				</Paragraph>
			</TextContainer>
			<Example fn={NoBackgroundExample} />
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
			</TextContainer>
			<Knobs
				component={ActionBar}
				initialProps={{ primary: { children: "Primary", onClick: console.log } }}
				exampleValues={{
					secondaries: {
						"single secondary": [{ children: "Secondary", onClick: console.log }],
						"two secondaries": [
							{ children: "Uno", onClick: console.log },
							{ children: "Dos", onClick: console.log },
						],
					},
					cancel: { cancel: { children: "Cancel", onClick: console.log } },
					destructive: { destrutive: { children: "Destructive", onClick: console.log } },
				}}
			/>
			<TextContainer article>
				<Header.H3>Destructive Action Bar?</Header.H3>
			</TextContainer>
			<Knobs
				component={DestructiveActionBar}
				initialProps={{
					destructive: { children: "Destroy!", onClick: console.log },
					cancel: { children: "Cancel", onClick: console.log },
				}}
			/>
			<TextContainer article>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>ActionBar</Code> when
					</Paragraph>
					<Ul
						items={[
							<>Confirming choices: (e.g., "Save Changes," "Delete," "Cancel")</>,
							<>Submitting information: (e.g., "Submit Form," "Complete task")</>,
							<>Navigating steps: (e.g., "Next," "Previous," "Skip")</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>ActionBar</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Top of content</b>: To guide users towards the next step in a process.
							</>,
							<>
								<b>Bottom of content</b>: For confirming choices or submitting after reviewing
								information.
							</>,
							<>
								<b>Within Forms</b>: To enable in-context actions related to the form data.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>ActionBar</Code> to existing components for visual
								consistency.
							</>,
							<>
								<b>Button styles</b>: Use primary, secondary, or default button styles to indicate
								action priority. Icon-only and icon with text label buttons are not currently
								supported.
							</>,
							<>Limit the total number of buttons to 3 for optimal usability.</>,
							<>
								<b>Avoid combining primary and destructive actions</b>: Never place the primary
								action and the destructive (delete) button in the same Action Bar.
							</>,
						]}
					/>
					<Header.H4>Interaction </Header.H4>
					<Ul
						items={[
							<>
								<b>User input safety</b>: In forms or modals, provide separate confirmation for
								destructive actions to prevent accidental data loss.
							</>,
							<>
								<b>More than 1 secondary action</b>: The <Code>ActionBar</Code> currently supports
								up to 2 secondary actions as buttons. Please use them sparingly. The{" "}
								<Code>ActionBar</Code> component does not support <Code>ActionMenu</Code>.
							</>,
							<>Limit the total number of buttons to 3 for optimal usability.</>,
							<>
								If <Code>ActionBar</Code> are placed at the bottom of the screen, it should stick as
								the user scrolls.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								<b>Standalone actions</b>: Single, isolated actions are better suited for individual{" "}
								<a href="https://fancy.siteimprove.com/lib/components/Actions%20and%20controls/Button">
									buttons
								</a>
								.
							</>,
							<>
								<b>Excessive actions</b>: If numerous actions are required, explore alternative
								layouts, or breaking the action into multiple steps.
							</>,
							<>the action is not related to the progress or status of a task.</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								Use verbs to clearly indicate the action's outcome. View{" "}
								<a href="https://fancy.siteimprove.com/Writing/word-list">Word list</a>.
							</>,
							<>Choose labels that leave no room for misinterpretation.</>,
							<>Maintain consistency with labels used in other parts of the interface. </>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const basicUsage = () => <ActionBar primary={{ children: "Primary", onClick: console.log }} />;

const delay = (n: number) => new Promise((resolve) => setTimeout(resolve, n));

const LoadingExample = () => {
	const [inProgress, setInProgess] = useState<string | null>(null);

	async function handler(action: string) {
		setInProgess(action);
		await delay(3000);
		setInProgess(null);
	}

	return (
		<ActionBar
			inProgressAction={inProgress}
			primary={{ children: "Primary", onClick: handler }}
			secondaries={[{ children: "Secondary", onClick: handler }]}
			cancel={{ children: "Cancel", onClick: console.log }}
		/>
	);
};

const LinkExample = () => (
	<ActionBar
		primary={{ children: "Open new tab", href: "https://siteimprove.com", openNew: true }}
		secondaries={[{ children: "Navigate in this tab", href: "https://siteimprove.com" }]}
	/>
);

const NoPaddingExample = () => (
	<ActionBar primary={{ children: "Primary", onClick: console.log }} noPadding />
);

const NoBackgroundExample = () => (
	<ActionBar primary={{ children: "Primary", onClick: console.log }} noBackground />
);
